import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';

import AnchorLink from '../components/AnchorLink';
import Bio from '../components/Bio';
import PostExcerpt from '../components/PostExcerpt';


export default class IndexPage extends React.Component {
    render() {
        const siteMetadata = this.props.data.site.siteMetadata;
        const posts = this.props.data.allMarkdownRemark.edges;
        const image = this.props.data.banner.childImageSharp.fluid.src;

        return (
            <>
                <Helmet title={siteMetadata.title}>
                    <html lang="en"/>
                    <link rel="canonical" href={siteMetadata.siteUrl} />
                    <meta name="description" value={siteMetadata.description} />
                    <meta name="author" value={siteMetadata.author.fullName} />
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={siteMetadata.title} />
                    <meta property="og:description" content={siteMetadata.description} />
                    <meta property="og:image" content={image} />
                    <meta property="twitter:site:id" content={siteMetadata.twitterId} />
                </Helmet>
                <main className="index-page">
                    <section className="introduction">
                        <h1>Welcome</h1>
                        <p>{siteMetadata.description}</p>
                        <p>
                            Here, I share thoughts about the tools I test and explain
                            the techniques I use.
                            In addition to isolated articles, I develop more in-depth examples
                            in article series. In those, we build fun open-source projects
                            from the ground up.
                        </p>
                        <p>{'Open series are: '}
                            <strong><Link to="/cryptomate">cryptomate</Link></strong>{', '}
                            <strong><Link to="/software">software engineering</Link></strong>{' and '}
                            <strong><Link to="/python">python</Link></strong>.
                        </p>
                    </section>
                    <section className="post-list">
                        <h1>What's new?</h1>
                        {posts.map(({ post }) => ( <PostExcerpt key={post.fields.slug} post={post} /> ))}
                    </section>
                    <AnchorLink className="back-to-top" href="#">back to top</AnchorLink>
                </main>
                <aside>
                    <Bio person={siteMetadata.author} />
                </aside>
            </>
        )
    }
}

export const pageQuery = graphql`
    query IndexQuery {
        site {
            siteMetadata {
                title
                description
                siteUrl
                twitterId
                author { ...BioPerson }
            }
        }
        allMarkdownRemark(filter: { fields: { showOnIndex: { eq: true } } },
                          sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                post: node { ...PostExcerpt }
            }
        }
        banner: file(relativePath: { eq: "default-banner.jpg" }) {
            childImageSharp { fluid { src } }
        }
    }
`
